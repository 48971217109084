/* eslint-disable prettier/prettier */
import { setStoreValue } from '@/store/utils';

export const SET_TIMESHEET_VERIFIERS = "SET_TIMESHEET_VERIFIERS";
export const SET_TIMESHEET_APPROVERS = "SET_TIMESHEET_APPROVERS";
export const SET_HIRING_MANAGERS = "SET_HIRING_MANAGERS";
export const SET_IS_FETCHING_TIMESHEET_VERIFIERS = "SET_IS_FETCHING_TIMESHEET_VERIFIERS";
export const SET_IS_FETCHING_TIMESHEET_APPROVERS = "SET_IS_FETCHING_TIMESHEET_APPROVERS";
export const SET_IS_FETCHING_HIRING_MANAGERS = "SET_IS_FETCHING_HIRING_MANAGERS";
export const SET_IS_FETCHING_USER_SETTINGS = "SET_IS_FETCHING_USER_SETTINGS";
export const SET_IS_ADDING_USER = "SET_IS_ADDING_USER";
export const SET_IS_EDITING_USER_DETAILS = "SET_IS_EDITING_USER_DETAILS";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const SET_IS_FETCHING_USER_ROLES = "SET_IS_FETCHING_USER_ROLES";

const usersMutations = {
  [SET_TIMESHEET_VERIFIERS]: setStoreValue("timesheetVerifiers"),
  [SET_TIMESHEET_APPROVERS]: setStoreValue("timesheetApprovers"),
  [SET_HIRING_MANAGERS]: setStoreValue("hiringManagers"),
  [SET_IS_FETCHING_TIMESHEET_VERIFIERS]: setStoreValue("isFetchingVerifiers"),
  [SET_IS_FETCHING_TIMESHEET_APPROVERS]: setStoreValue("isFetchingApprovers"),
  [SET_IS_FETCHING_HIRING_MANAGERS]: setStoreValue("isFetchingHiringManagers"),
  [SET_USER_SETTINGS]: setStoreValue("userSettings"),
  [SET_IS_FETCHING_USER_SETTINGS]: setStoreValue("isFetchingUserSettings"),
  [SET_IS_ADDING_USER]: setStoreValue("isAddingUser"),
  [SET_IS_EDITING_USER_DETAILS]: setStoreValue("isEditingUserDetails"),
  [SET_IS_FETCHING_USER_ROLES]: setStoreValue("isFetchingUserRoles"),
};

export default usersMutations;
