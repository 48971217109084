<template v-slot:body="{ items }">
  <div>
    <div
      class="flex-item-wrapper bordered clickable"
      v-for="(item, index) in items"
      :key="index"
      @click="() => selectItem(item)"
    >
      <div class="mobile-item-header">
        <div class="d-flex align-center">
          <TimesheetChip :status="item.status">
            {{ item.status }}
          </TimesheetChip>
          <div class="ml-2">TS-{{ item.id }}</div>
        </div>
      </div>
      <div class="item-title font-weight-bold">
        {{ item.job | capitalize }}
      </div>
      <div class="item-title font-weight-regular mb-2">
        {{ `w/c ${item.weekCommencingLongDate}` }}
      </div>
      <Permissioned class="flex-item">
        <div permission="timesheets.view.total-cost">
          <div class="table-label">Cost</div>
          <div>
            {{ item.cost | cost | currency(item.currencyCode) }}
          </div>
        </div>
      </Permissioned>
      <div v-for="(header, index) in headers" :key="index" class="flex-item">
        <div class="table-label">{{ header.text }}</div>
        <div>
          {{ item[header.value] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimesheetChip from "@/views/timesheets/components/TimesheetChip";
import Permissioned from "@/components/common/Permissioned";

export default {
  name: "TimesheetListMobile",
  components: {
    Permissioned,
    TimesheetChip
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    selectItem(item) {
      this.$emit("onSelect", item);
    }
  }
};
</script>

<style lang="scss" scoped></style>
