<template>
  <div class="timesheets">
    <Datatable
      :headers="headers"
      :items="items"
      :customSort="customSort"
      :customFilter="customFilter"
      :search="filters.search"
      :itemsPerPage="page.pageSize"
      :isLoading="isLoadingTimesheets"
      :filterCount="filterCount"
      @onClearFilters="clearFilters"
      @onSelect="handleSelect"
      customTableBody
      mustSort
      hideRowSeparator
      disablePagination
    >
      <template v-slot:body="{ items }" v-if="isMobile">
        <TimesheetListMobile
          @onSelect="handleSelect"
          :items="items"
          :headers="mobileHeaders"
        />
      </template>
      <template v-slot:filters>
        <TimesheetsListFilter
          :timesheets="items"
          @filter="updateFilters"
          :key="filtersId"
        />
      </template>
      <template v-slot:cost="{ item }">
        <span>
          {{ item.cost | cost | currency(item.currencyCode) }}
        </span>
      </template>
      <template v-slot:status="{ item }">
        <TimesheetChip :status="item.status">
          {{ item.status }}
        </TimesheetChip>
      </template>
      <template v-slot:pagination>
        <v-pagination
          :total-visible="totalPagesVisible"
          v-if="showPagination"
          v-model="page.currentPage"
          @input="fetchData"
          :length="page.lastPage"
          circle
        />
      </template>
    </Datatable>
  </div>
</template>

<script>
import Datatable from "@/components/common/Datatable";
import { sortTableItems } from "@/utils/helpers";
import { size, map, filter, includes } from "lodash";
import { getColorByStatus, TIMESHEET_STATUSES } from "@/utils/timesheets";
import moment from "moment";
import { BASE_DATE_FORMAT, LONG_DATE_FORMAT } from "@/constants/common.js";
import TimesheetsListFilter from "@/views/timesheets/components/TimesheetListFilter";
import TimesheetChip from "@/views/timesheets/components/TimesheetChip";
import generateId from "uuid/v4";
import { createNamespacedHelpers } from "vuex";
import { customTableFussyFilter } from "@/utils/filters";
import { isPermissioned } from "@/utils/permissions";
import TimesheetListMobile from "@/views/timesheets/components/TimesheetListMobile";
import { EDITABLE_STATUSES } from "@/views/timesheets/TimesheetList.vue";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { GET_ALL_TIMESHEETS } from "@/store/modules/timesheets/actions";
import { paginationMixin } from "@/mixins/pagination.mixin";
import { getFullName } from "@/utils/users";

const {
  mapState: timesheetMapState,
  mapActions: timesheetMapActions
} = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  mixins: [paginationMixin],
  components: {
    Datatable,
    TimesheetsListFilter,
    TimesheetChip,
    TimesheetListMobile
  },
  created() {
    this.fetchData();
  },
  props: {
    additionalFilters: Object,
    showColumns: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    filters: [],
    statuses: [...Object.values(TIMESHEET_STATUSES)],
    sort: "",
    filtersId: generateId()
  }),
  computed: {
    ...timesheetMapState({
      timesheets: state => state.timesheets,
      isLoadingTimesheets: state => state.isFetchingTimesheets
    }),
    currentWorkerId() {
      return this.$route.params.id;
    },
    items() {
      return map(this.timesheets, timesheet => {
        const {
          currency_code,
          cost,
          weekCommencing,
          rootClient,
          worker,
          title
        } = timesheet;
        const formattedWeekCommencing = moment(timesheet.weekCommencing).format(
          BASE_DATE_FORMAT
        );
        const formattedWeekCommencingLongDate = weekCommencing
          ? moment(weekCommencing).format(LONG_DATE_FORMAT)
          : "";
        return {
          ...timesheet,
          job: title,
          worker: getFullName(worker),
          formattedWeekCommencing,
          weekCommencingLongDate: formattedWeekCommencingLongDate,
          cost,
          currencyCode: currency_code,
          rootClient: rootClient ? rootClient.name : ""
        };
      });
    },
    headers() {
      const headers = [
        {
          text: "Timesheet ID",
          value: "id",
          customMobileView: true,
          class: "max-width-200"
        },
        {
          text: "Week Commencing",
          value: "formattedWeekCommencing",
          customMobileView: true,
          class: "max-width-200"
        },
        this.showColumns.includes("job") && {
          text: "Job",
          value: "job",
          customMobileView: true,
          class: "min-width-200"
        },
        isPermissioned("timesheets.view.worker") &&
          this.showColumns.includes("worker") && {
            text: "Worker",
            value: "worker",
            class: "min-width-200"
          },
        {
          text: "Status",
          value: "status",
          customMobileView: true
        },
        isPermissioned("timesheets.view.total-cost") && {
          text: "Cost",
          value: "cost",
          customMobileView: true,
          class: "min-width-200"
        },
        isPermissioned("timesheets.view.root-client") && {
          text: "Organisation",
          value: "rootClient",
          class: "min-width-200"
        }
      ];

      return headers.filter(Boolean);
    },
    mobileHeaders() {
      return filter(this.headers, ({ customMobileView }) => !customMobileView);
    },
    searchKeys() {
      return map(this.headers, "value");
    },
    isErrorVisible() {
      return !!this.error;
    },
    filterCount() {
      return size(this.filters);
    },
    requestParams() {
      return {
        filter: {
          ...this.filters,
          ...this.additionalFilters
        },
        "page[size]": this.page.pageSize,
        "page[number]": this.page.currentPage,
        ...(this.sort && { sort: this.sort })
      };
    }
  },
  methods: {
    ...timesheetMapActions({
      getTimesheets: GET_ALL_TIMESHEETS
    }),
    async fetchData() {
      const { meta } = await this.getTimesheets(this.requestParams);
      this.updatePageDetails(meta);
    },
    customSort(items, arr, isDescArray) {
      const options = { dateValuesLabels: ["format  tedWeekCommencing"] };
      return sortTableItems(items, arr, isDescArray, options);
    },
    getStatusColor(status) {
      return getColorByStatus(status);
    },
    customFilter(value, searchValue, item) {
      return customTableFussyFilter(searchValue, item, this.searchKeys);
    },
    clearFilters() {
      this.filtersId = generateId();
      this.filters = [];
      this.fetchData();
      this.resetPagination();
    },
    updateFilters(filters) {
      this.filters = filters;
      this.fetchData(filters);
      this.resetPagination();
    },
    handleSelect({ id, status }) {
      const showSubmitTimesheetView =
        includes(EDITABLE_STATUSES, status) &&
        isPermissioned(`timesheets[${id}].edit`);
      this.$router.push({
        name: showSubmitTimesheetView ? "submitTimesheet" : "timesheetDetails",
        params: { id }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
