<template>
  <DialogFull
    :steps="{ current: step, total: totalSteps }"
    :data="dialogSettings"
    progress
    @onStepBack="stepBack"
    @close="close"
  >
    <template v-slot:dialog.body>
      <RoleStep
        v-if="shouldStepViewBeShown(1) || ifStepHasAlreadyBeenCompleted(1)"
        @nextStep="stepForward"
        v-show="shouldStepViewBeShown(1)"
      />
      <BasicDetails
        :initial-template="bookingData.template"
        v-if="shouldStepViewBeShown(2) || ifStepHasAlreadyBeenCompleted(2)"
        v-show="shouldStepViewBeShown(2)"
        :validate="shouldStepViewBeShown(2) && shouldValidate"
        :showValidationErrors="showValidationErrors"
        @change="updateData"
        @valid="stepForward"
        @invalid="shouldValidate = false"
      />
      <InternalInformation
        v-if="shouldStepViewBeShown(3) || ifStepHasAlreadyBeenCompleted(3)"
        v-show="shouldStepViewBeShown(3)"
        :validate="shouldStepViewBeShown(3) && shouldValidate"
        @valid="internalInfoStepForward"
        @invalid="shouldValidate = false"
        @change="updateData"
      />
      <ShiftStep
        v-if="shouldStepViewBeShown(4) || ifStepHasAlreadyBeenCompleted(4)"
        v-show="shouldStepViewBeShown(4)"
        :validate="shouldStepViewBeShown(4) && shouldValidate"
        :isNewBooking="true"
        @valid="handleSubmit"
        @change="updateData"
      />
    </template>
    <template v-slot:dialog.action v-if="step > 1">
      <PrimaryButton
        :loading="isLoading"
        @click.native="validateStep"
        v-if="step < totalSteps"
      >
        Continue
      </PrimaryButton>
      <PrimaryButton @click.native="validateStep" :loading="isLoading" v-else>
        Create Booking
      </PrimaryButton>
    </template>
  </DialogFull>
</template>

<script>
import BasicDetails from "@/views/bookings/NewBooking/steps/BasicDetails";
import RoleStep from "@/views/bookings/NewBooking/steps/RoleStep";
import InternalInformation from "@/views/bookings/NewBooking/steps/InternalInformation";
import DialogFull from "@/components/common/DialogFull";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import ShiftStep from "@/views/bookings/NewBooking/steps/ShiftStep";
import {
  BOOKINGS_NAMESPACE,
  CREATE_BOOKING,
  CREATE_SHIFT_PATTERN
} from "@/store/modules/bookings/actions";
import { createNamespacedHelpers } from "vuex";
import { get, find } from "lodash";
import { CONTRACT_TYPE_NAMES } from "@/constants/bookings";
import { generateDefaultShiftForFixedTerm } from "@/utils/shifts";

const { mapActions } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
const MAX_STEP = 4;

export default {
  name: "NewBooking",
  components: {
    PrimaryButton,
    DialogFull,
    BasicDetails,
    RoleStep,
    InternalInformation,
    ShiftStep
  },
  data() {
    return {
      newBookingId: null,
      step: 1,
      savedStep: 1,
      maxSteps: MAX_STEP,
      bookingData: {},
      isLoading: false,
      shouldValidate: false,
      showValidationErrors: false
    };
  },
  computed: {
    dialogSettings() {
      return {
        dialog: this.isOpen,
        title: "New Booking"
      };
    },
    isFixedTerm() {
      return this.bookingData.contractType === CONTRACT_TYPE_NAMES.FIXED_TERM;
    },
    totalSteps() {
      return this.isFixedTerm ? this.maxSteps - 1 : this.maxSteps;
    },
    defaultActivity() {
      const { templatePayRates } = this.bookingData.template;
      const defaultPayRate = find(templatePayRates, { isDefault: true });
      return defaultPayRate ? defaultPayRate.payRateName : "Standard";
    }
  },
  methods: {
    ...mapActions({
      createBooking: CREATE_BOOKING,
      createShift: CREATE_SHIFT_PATTERN
    }),
    stepBack() {
      if (this.step > 1) {
        this.step -= 1;
      }
    },
    updateData(bookingData) {
      this.bookingData = { ...this.bookingData, ...bookingData };
      this.shouldValidate = false;
    },
    validateStep() {
      this.shouldValidate = true;
      this.showValidationErrors = true;
    },
    clearForm() {
      this.step = 1;
      this.savedStep = 1;
      this.bookingData = {};
    },
    async stepForward(bookingData) {
      this.bookingData = { ...this.bookingData, ...bookingData };
      this.step += 1;
      if (this.savedStep < this.totalSteps) {
        this.savedStep += 1;
      }
      this.shouldValidate = false;
      this.showValidationErrors = false;
    },
    internalInfoStepForward(bookingData) {
      this.isFixedTerm ? this.handleSubmit() : this.stepForward(bookingData);
    },
    async handleSubmit() {
      try {
        this.isLoading = true;
        // don't create a new booking when it's already created
        // a condition created in case the shift creation would return an error and the action would have to be repeated
        if (!this.newBookingId) {
          const {
            data: { id: bookingId }
          } = await this.createBooking({
            templateId: this.bookingData.template.id,
            description: this.bookingData.description,
            contractType: this.bookingData.contractType,
            location: this.bookingData.location,
            costCode: this.bookingData.client.costCode,
            poNumber: this.bookingData.poNumber,
            postNumber: this.bookingData.postNumber,
            hireReason: this.bookingData.reason,
            expensesAllowed: this.bookingData.expensesAllowed,
            assessmentsAllowed: this.bookingData.conductQualityAssessments,
            hiringManagerId: get(this.bookingData, "hiringManager.id"),
            timesheetApproverUserId: get(this.bookingData, "approver.id"),
            timesheetVerifierUserId: get(this.bookingData, "verifier.id"),
            client: this.bookingData.client,
            complianceRequirements: this.bookingData.complianceRequirements,
            otherRequirements: this.bookingData.otherRequirements,
            internalNotes: this.bookingData.internalNotes
          });
          this.newBookingId = bookingId;
        }
        if (this.isFixedTerm) {
          const defaultShift = generateDefaultShiftForFixedTerm({
            startDate: this.bookingData.startDate,
            endDate: this.bookingData.endDate,
            activity: this.defaultActivity
          });

          this.bookingData.shifts = [defaultShift];
        }
        const shiftRequests = this.bookingData.shifts.map(shift =>
          this.createShift({
            bookingId: this.newBookingId,
            shift
          })
        );
        await Promise.all(shiftRequests);
        this.clearForm();
        await this.$router.push(`/bookings/${this.newBookingId}/details`);
        this.$emit("close");
      } finally {
        this.isLoading = false;
      }
    },
    close() {
      this.clearForm();
      this.$emit("close");
    },
    shouldStepViewBeShown(viewStep) {
      return this.step === viewStep;
    },
    ifStepHasAlreadyBeenCompleted(viewStep) {
      return viewStep <= this.savedStep;
    }
  },
  props: {
    isOpen: Boolean
  }
};
</script>

<style lang="scss"></style>
