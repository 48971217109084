import { USER_MODEL } from "@/models/user-model";
import { PUBLISHING_SCHEDULE_MODEL } from "@/models/publishing-schedule-model";
import { SHIFT_MODEL } from "@/models/shift-model";
import { EVENT_MODEL } from "@/models/event-model";
import { CALCULATED_COST_MODEL } from "@/models/calculated-cost-model";
import { BOOKING_APPROVER_MODEL } from "@/models/booking-approver-model";
import { PAY_RATE_MODEL } from "@/models/pay-rate-model";

export const BOOKING_MODEL = "booking";
export const UPDATE_PO_NUMBER = "update-po-number";

const bookingModel = {
  templateId: "",
  title: "",
  contractType: "",
  costCode: "",
  status: "",
  booking_description: "",
  description: "",
  poNumber: 0,
  postNumber: 0,
  hireReason: "",
  currencyCode: "",
  expensesAllowed: false,
  totalCost: 0,
  assessmentsAllowed: false,
  timesheetApproverUserId: "",
  timesheetVerifierUserId: "",
  addressId: "",
  otherRequirements: [],
  yearsReferencesRequired: 0,
  complianceRequirements: [],
  submittedForApprovalDate: "",
  approvedDate: "",
  filled: false,
  filledPercentage: 0,
  files: [],
  internalNotes: "",
  hiringManagerId: "",
  timeUnitName: "",
  location: {
    jsonApi: "hasOne",
    type: "addresses"
  },
  verifier: {
    jsonApi: "hasOne",
    type: "users"
  },
  manager: {
    jsonApi: "hasOne",
    type: "users"
  },
  approver: {
    jsonApi: "hasOne",
    type: "users"
  },
  shiftPatterns: {
    jsonApi: "hasMany",
    type: "shiftPattern"
  },
  applications: {
    jsonApi: "hasMany",
    type: "application"
  },
  comments: {
    jsonApi: "hasMany",
    type: "comment"
  },
  costs: {
    jsonApi: "hasMany",
    type: CALCULATED_COST_MODEL
  },
  shift: {
    jsonApi: "hasMany",
    type: SHIFT_MODEL
  },
  candidates: {
    jsonApi: "hasMany",
    type: USER_MODEL
  },
  events: {
    jsonApi: "hasMany",
    type: EVENT_MODEL
  },
  bookingApprovers: {
    jsonApi: "hasMany",
    type: BOOKING_APPROVER_MODEL
  },
  publishingSchedules: {
    jsonApi: "hasMany",
    type: PUBLISHING_SCHEDULE_MODEL
  },
  payRates: {
    jsonApi: "hasMany",
    type: PAY_RATE_MODEL
  },
  rootClient: {
    jsonApi: "hasOne",
    type: "organisations"
  },
  client: {
    jsonApi: "hasOne",
    type: "organisations"
  }
};

export const bookingModelActions = [
  "accept",
  "cancel",
  "approve",
  "reject",
  "publish",
  "submit-for-approval",
  "timesheet-approver",
  "timesheet-verifier",
  "delete",
  "reopen",
  "bulk-create",
  "time-unit"
];

export default bookingModel;
