<template>
  <BaseDialog :isOpen="isOpen">
    <template v-slot:title> Convert booking to {{ rateLabel }} rate? </template>
    <template v-slot:body>
      <div class="flex flex-column align-center mt-5 mb-3">
        <div
          v-for="(activity, index) in activities"
          :key="index"
          class="flex flex-row align-center flex-gap-20 mb-2"
        >
          <div class="half-width">{{ activity.activityTypeName }}</div>
          <v-text-field
            class="mt-0 half-width"
            v-model="activity.payRateAmount"
            v-money="moneyConfig"
            prefix="£"
          />
        </div>
      </div>
    </template>
    <template v-slot:actions>
      <div class="accept-booking-actions">
        <PrimaryButton @click.native="cancel" text>
          Cancel
        </PrimaryButton>
        <PrimaryButton @click.native="handleSubmit" :loading="isLoading">
          Convert Time Unit
        </PrimaryButton>
      </div>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from "@/components/common/BaseDialog";
import PrimaryButton from "@/components/common/Button/PrimaryButton";
import { createNamespacedHelpers } from "vuex";
import {
  BOOKINGS_NAMESPACE,
  EDIT_TIME_UNIT,
  FETCH_BOOKING_PAYRATES
} from "@/store/modules/bookings/actions";
import { filter, map } from "lodash";
import { GET_BOOKING_PAYRATES } from "@/store/modules/bookings/getters";
import { getAmountInApiFormat, getMonetaryAsNumber } from "@/utils/helpers";

const { mapActions, mapGetters } = createNamespacedHelpers(BOOKINGS_NAMESPACE);
export default {
  name: "ConvertTimeUnitDialog",
  components: { PrimaryButton, BaseDialog },
  props: {
    isOpen: Boolean,
    timeUnit: String
  },
  data() {
    return {
      isLoading: false,
      rate: "",
      moneyConfig: { precision: 2 },
      activities: []
    };
  },
  computed: {
    ...mapGetters({
      bookingPayRates: GET_BOOKING_PAYRATES
    }),
    rateLabel() {
      const labels = { hour: "hourly", day: "day" };
      return labels[this.timeUnit] ?? "";
    },
    allActivities() {
      return filter(
        this.bookingPayRates,
        payRate =>
          Date.parse(payRate.validToDate) > Date.now() && payRate.isDefault
      );
    }
  },
  inject: ["currentBookingId"],
  methods: {
    ...mapActions({
      editTimeUnit: EDIT_TIME_UNIT,
      fetchBookingPayRates: FETCH_BOOKING_PAYRATES
    }),
    async handleSubmit() {
      this.isLoading = true;
      await this.editTimeUnit({
        bookingId: this.currentBookingId,
        data: {
          timeUnitName: this.timeUnit,
          payRates: map(this.activities, activity => ({
            activityTypeName: activity.activityTypeName,
            payRateAmount: getAmountInApiFormat(
              getMonetaryAsNumber(activity.payRateAmount)
            )
          }))
        }
      });
      await this.fetchBookingPayRates({
        bookingId: this.currentBookingId
      });
      this.$emit("close");
      this.isLoading = false;
    },
    cancel() {
      this.$emit("close");
    }
  },
  watch: {
    bookingPayRates(newValue) {
      const payrates = filter(
        newValue,
        payRate =>
          Date.parse(payRate.validToDate) > Date.now() && payRate.isDefault
      );
      this.activities = map([...payrates], payrate => ({
        activityTypeName: payrate.activityTypeName,
        payRateAmount: ""
      }));
    }
  }
};
</script>
