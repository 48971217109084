import {
  handleWithErrorMessage,
  handleWithMessageAndLoading,
  addGlobalMessage,
  errorHandler,
  handleWithGlobalMessage
} from "@/services/utils";
import api from "@/services/modules/users";
import {
  SET_IS_FETCHING_TIMESHEET_APPROVERS,
  SET_IS_FETCHING_TIMESHEET_VERIFIERS,
  SET_IS_ADDING_USER,
  SET_IS_EDITING_USER_DETAILS,
  SET_IS_FETCHING_USER_ROLES,
  SET_TIMESHEET_APPROVERS,
  SET_TIMESHEET_VERIFIERS,
  SET_IS_FETCHING_USER_SETTINGS,
  SET_USER_SETTINGS
} from "@/store/modules/users/mutations";
import { SET_ORGANISATION_USERS } from "@/store/modules/settings/mutations";
import { GET_ORGANISATION_USERS } from "@/store/modules/settings/getters";
import { fromSettings } from "@/store/modules/settings";
import { size, first } from "lodash";
import { SET_HIRING_MANAGERS, SET_IS_FETCHING_HIRING_MANAGERS } from "./mutations";

export const FETCH_TIMESHEET_VERIFIERS = "FETCH_TIMESHEET_VERIFIERS";
export const FETCH_TIMESHEET_APPROVERS = "FETCH_TIMESHEET_APPROVERS";
export const FETCH_HIRING_MANAGERS = "FETCH_HIRING_MANAGERS";
export const FETCH_USER_SETTINGS = "FETCH_USER_SETTINGS";
export const EDIT_USER_SETTINGS = "EDIT_USER_SETTINGS";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER_DETAILS = "EDIT_USER_DETAILS";
export const FETCH_USER_ROLES = "FETCH_USER_ROLES";
export const CHECK_EXISTING_EMAIL = "CHECK_EXISTING_EMAIL";
export const INVITE_USER = "INVITE_USER";
export const RESET_USER_PASSWORD = "RESET_USER_PASSWORD";

const usersActions = {
  async [FETCH_TIMESHEET_VERIFIERS]({ dispatch, commit }, organisationId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getTimesheetVerifiers(organisationId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_TIMESHEET_VERIFIERS, isLoading),
      setDataFunction: ({ data }) => commit(SET_TIMESHEET_VERIFIERS, data)
    });
  },
  async [FETCH_TIMESHEET_APPROVERS]({ dispatch, commit }, organisationId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getTimesheetApprovers(organisationId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_TIMESHEET_APPROVERS, isLoading),
      setDataFunction: ({ data }) => commit(SET_TIMESHEET_APPROVERS, data)
    });
  },
  async [FETCH_HIRING_MANAGERS]({ dispatch, commit }, organisationId) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).getHiringManagers(organisationId),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_HIRING_MANAGERS, isLoading),
      setDataFunction: ({ data }) => commit(SET_HIRING_MANAGERS, data)
    });
  },
  async [FETCH_USER_SETTINGS]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getUserSettings,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_USER_SETTINGS, isLoading),
      setDataFunction: ({ data }) => commit(SET_USER_SETTINGS, data)
    });
  },
  async [EDIT_USER_SETTINGS]({ dispatch, commit }, editedSettings) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).editUserSettings(editedSettings),
      setDataFunction: ({ data }) => {
        commit(SET_USER_SETTINGS, data.attributes);
        addGlobalMessage(dispatch, {
          type: "success",
          meta: { title: "Successfully updated" }
        });
      }
    });
  },
  async [CREATE_USER]({ dispatch, commit, rootGetters }, userData) {
    const organisationUsers = rootGetters[fromSettings(GET_ORGANISATION_USERS)];
    return handleWithMessageAndLoading({
      dispatch,
      setLoadingFunction: isLoading => commit(SET_IS_ADDING_USER, isLoading),
      request: () => api(commit).createUser(userData),
      setDataFunction: data => {
        const updatedUsers = [...organisationUsers, data];
        commit(fromSettings(SET_ORGANISATION_USERS), updatedUsers, {
          root: true
        });
      }
    });
  },
  async [EDIT_USER_DETAILS]({ dispatch, commit, rootGetters }, userData) {
    const organisationUsers = rootGetters[fromSettings(GET_ORGANISATION_USERS)];
    return handleWithMessageAndLoading({
      dispatch,
      setLoadingFunction: isLoading =>
        commit(SET_IS_EDITING_USER_DETAILS, isLoading),
      request: () => api(commit).editUserDetails(userData),
      setDataFunction: data => {
        const updatedUsers = [...organisationUsers].map(user => ({
          ...user,
          ...[data].find(item => item && item.id === user.id)
        }));

        commit(fromSettings(SET_ORGANISATION_USERS), updatedUsers, {
          root: true
        });
      }
    });
  },
  async [FETCH_USER_ROLES]({ dispatch, commit }, id) {
    return handleWithErrorMessage({
      dispatch,
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_USER_ROLES, isLoading),
      request: () => api(commit).getUserRoles(id)
    });
  },
  async [CHECK_EXISTING_EMAIL]({ dispatch, commit }, email) {
    try {
      const filter = { email };
      const { data } = await api(commit).getUsers({ filter });
      const isExistingEmail = Boolean(size(data));
      return { user: first(data), isExistingEmail };
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [INVITE_USER]({ dispatch, commit }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).inviteUser(id)
    });
  },
  async [RESET_USER_PASSWORD]({ dispatch, commit }, id) {
    return await handleWithGlobalMessage(
      () => api(commit).resetUserPassword(id),
      dispatch
    );
  }
};

export default usersActions;
