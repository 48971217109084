import usersActions from "@/store/modules/users/actions";
import usersMutations from "@/store/modules/users/mutations";
import usersGetters from "@/store/modules/users/getters";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_USERS_STATE } from "@/constants/users";

const state = {
  timesheetVerifiers: [],
  timesheetApprovers: [],
  hiringManagers: [],
  isFetchingApprovers: false,
  isFetchingVerifiers: false,
  isFetchingHiringManagers: false,
  isFetchingUserSettings: false,
  userSettings: null,
  isAddingUser: false,
  isEditingUserDetails: false,
  isFetchingUserRoles: false
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_USERS_STATE : state,
  actions: isMockedDataEnabled() ? {} : usersActions,
  mutations: isMockedDataEnabled() ? {} : usersMutations,
  getters: usersGetters
};
