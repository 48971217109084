import api from "@/services/modules/auth";
import {
  SET_ERROR,
  SET_FETCHING_USERS,
  SET_IS_LOADING_SELF_DETAILS,
  SET_IS_EDITING_DETAILS,
  SET_USERS_TO_IMPERSONATE
} from "./mutation-types";
import {
  FETCH_SELF_DETAILS,
  FETCH_USERS_TO_IMPERSONATE,
  EDIT_USER_DETAILS,
  SELECT_PROFILE,
  FETCH_APP_LOGO
} from "./action-types";
import { setStoreValue } from "@/store/utils";
import {
  handleWithErrorMessage,
  addGlobalMessage,
  getImpersonateUserId
} from "@/services/utils";
import {
  SET_MODULE_PERMISSIONS_AS_CURRENT,
  SET_PERMISSIONS,
  SET_USER_DATA,
  SET_SELECTED_PROFILE,
  SET_PROFILES,
  SET_APP_LOGO,
  SET_API_ERROR
} from "@/store/modules/auth/mutation-types";
import { MOCKED_AUTH_STATE } from "@/constants/userdata";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import getters from "@/store/modules/auth/getters";
import { getSelectedProfile } from "@/services/utils";
import { find, size, first } from "lodash";

const state = {
  usersToImpersonate: [],
  userData: {},
  userToken: "",
  isLoadingSelfDetails: false,
  isEditingDetails: false,
  isFetchingUsers: false,
  authenticatingError: null,
  permissions: null,
  selectedProfile: null,
  profiles: [],
  appLogo: null,
  apiError: false
};

const actions = {
  async [FETCH_SELF_DETAILS]({ dispatch, commit }) {
    try {
      commit(SET_IS_LOADING_SELF_DETAILS, true);
      const { data, meta } = await api(commit).me();
      if (data && meta) {
        const { permissions, tokens } = meta;
        const { organisation } = data;
        commit(SET_USER_DATA, data);
        commit(SET_PERMISSIONS, permissions);
        commit(SET_PROFILES, tokens);
        if (organisation) {
          dispatch(FETCH_APP_LOGO, organisation.id);
        }
        if (size(tokens) === 1) {
          dispatch(SELECT_PROFILE, first(tokens));
        } else if (getImpersonateUserId()) {
          dispatch(
            SELECT_PROFILE,
            find(
              tokens,
              tokenDataItem => tokenDataItem.id === getImpersonateUserId()
            )
          );
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit(SET_IS_LOADING_SELF_DETAILS, false);
    }
  },
  async [EDIT_USER_DETAILS]({ commit, dispatch }, { id, editedUser }) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api(commit).update(id, editedUser),
      setLoadingFunction: isLoading =>
        commit(SET_IS_EDITING_DETAILS, isLoading),
      setDataFunction: ({ data, meta }) => {
        const { permissions } = meta;
        commit(SET_USER_DATA, data);
        commit(SET_PERMISSIONS, permissions);
        addGlobalMessage(dispatch, {
          type: "success",
          meta: { title: "Successfully updated" }
        });
      }
    });
  },
  async [FETCH_USERS_TO_IMPERSONATE]({ dispatch, commit }) {
    return handleWithErrorMessage({
      dispatch,
      request: api(commit).getUsersToImpersonate,
      setLoadingFunction: isLoading => commit(SET_FETCHING_USERS, isLoading),
      setDataFunction: ({ data }) => commit(SET_USERS_TO_IMPERSONATE, data)
    });
  },
  async [SELECT_PROFILE]({ state, commit }, profile) {
    commit(SET_IS_LOADING_SELF_DETAILS, true);
    commit(SET_SELECTED_PROFILE, profile);
    localStorage.setItem("selectedProfile", JSON.stringify(profile));
    localStorage.setItem("token", profile.token);
    if (size(state.profiles) > 1 && !getImpersonateUserId()) {
      window.location.reload();
    }
  },
  async [FETCH_APP_LOGO]({ dispatch, commit }, id) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getAppLogo(id),
      setDataFunction: ({ data }) => {
        const { attributes } = data;
        if (attributes) {
          commit(SET_APP_LOGO, attributes["app-logo"]);
        }
      }
    });
  }
};

const mutations = {
  [SET_USER_DATA]: setStoreValue("userData"),
  [SET_IS_LOADING_SELF_DETAILS]: setStoreValue("isLoadingSelfDetails"),
  [SET_IS_EDITING_DETAILS]: setStoreValue("isEditingDetails"),
  [SET_ERROR]: setStoreValue("authenticatingError"),
  [SET_USERS_TO_IMPERSONATE]: setStoreValue("usersToImpersonate"),
  [SET_FETCHING_USERS]: setStoreValue("isFetchingUsers"),
  [SET_PERMISSIONS]: (state, permissions) => {
    if (isMockedDataEnabled()) {
      state.permissions = permissions;
    }
    state.permissions = { ...state.permissions, ...permissions };
  },
  [SET_MODULE_PERMISSIONS_AS_CURRENT]: (state, { module, id }) => {
    state.permissions = Object.fromEntries(
      Object.entries(state.permissions).map(([permission, isAllowed]) => {
        const currentIndex = `[${id}]`;
        if (permission.startsWith(`${module}${currentIndex}`)) {
          return [permission.replace(currentIndex, ""), isAllowed];
        }
        return [permission, isAllowed];
      })
    );
  },
  [SET_SELECTED_PROFILE]: setStoreValue("selectedProfile"),
  [SET_PROFILES]: (state, profiles) => {
    const impersonating = Boolean(getImpersonateUserId());
    if (!impersonating) {
      state.profiles = profiles;
      const selectedProfile = getSelectedProfile();
      if (selectedProfile) {
        const profileToBeStored = find(profiles, { id: selectedProfile.id });
        state.selectedProfile = profileToBeStored;
        profileToBeStored &&
          localStorage.setItem("token", profileToBeStored.token);
      }
    }
  },
  [SET_APP_LOGO]: setStoreValue("appLogo"),
  [SET_API_ERROR]: setStoreValue("apiError")
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_AUTH_STATE : state,
  actions: isMockedDataEnabled() ? {} : actions,
  mutations: isMockedDataEnabled() ? {} : mutations,
  getters
};
